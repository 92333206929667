import axios from '@axios'
import baseConfig from '@/config/index'
const TICKET_API_SERVICE_PATH = baseConfig.TICKET_API_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrganizations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/organization/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrganization(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/organization/detail', { organizationId: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editOrganization(ctx, organizationData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/organization/edit', organizationData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOrganization(ctx, organizationData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/organization/delete', organizationData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOrganization(ctx, organizationData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/organization/create', organizationData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTeam(ctx, teamData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/team/create', teamData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editTeam(ctx, teamData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/team/edit', teamData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTeamsByFilter(ctx, teamData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/team/listByFilter', teamData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTeam(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/team/detail', { teamId: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTeam(ctx, teamData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/team/delete', teamData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
