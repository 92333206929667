import axios from '@axios'
import baseConfig from '@/config/index'
const TICKET_API_SERVICE_PATH = baseConfig.TICKET_API_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRefStatuses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refStatus/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefStatus(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refStatus/detail', { refStatusId: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRefStatus(ctx, refStatusData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refStatus/edit', refStatusData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRefStatus(ctx, refStatusData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refStatus/delete', refStatusData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRefStatus(ctx, refStatusData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refStatus/create', refStatusData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
