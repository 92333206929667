import axios from '@axios'
import baseConfig from '@/config/index'
const TICKET_API_SERVICE_PATH = baseConfig.TICKET_API_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/listByPaginate', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/detail', { username: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserProfile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/info', { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/create', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/edit', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editChildren(ctx, childData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/editChildren', childData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeUserStatus(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/changeStatus', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resetPasswordUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/resetPassword', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrganizations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/organization/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/role/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTeamsByFilter(ctx, teamData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/team/listByFilter', teamData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
