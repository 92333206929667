import axios from '@axios'
import baseConfig from '@/config/index'
const FILE_SERVICE_PATH = baseConfig.FILE_SERVICE_PATH
const TICKET_API_SERVICE_PATH = baseConfig.TICKET_API_SERVICE_PATH
const TEST_SERVICE_PATH = baseConfig.TEST_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasksByBoard(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/list/listByBoardWithTickets', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTasks(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/ticket/list', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNotes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/customer/getNoteListByCustomer', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/ticket/detail', { ticketId: taskData.id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        console.log(taskData)
        axios
          .post(TICKET_API_SERVICE_PATH + '/ticket/create', taskData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTask(ctx, taskData) {
      console.log(taskData)
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/ticket/edit', taskData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/todo/tasks/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatusTask(ctx, param) {
      console.log(param)
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/ticket/changeStatus', param)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    completeTask(ctx, param) {
      console.log(param)
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/ticket/complete', param)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTeamsByFilter(ctx, teamData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/team/listByFilter', teamData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    addComment(ctx, param) {
      console.log(param)
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/ticket/addComment', param)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCustomer(ctx, param) {
      console.log(param)
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/customer/getCustomer', param)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refCategory/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefCategoriesWithChildren(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refCategory/listWithChildren', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refProduct/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefProductsWithChildren(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refProduct/listWithChildren', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefPriorities(ctx, priorityData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refPriority/list', priorityData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    fetchRefTags(ctx, tagData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refTag/list', tagData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsersByFilter(ctx, tagData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/listByFilter', tagData )
          .then(response => resolve(response))
          // console.log(response)
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/detail', { username: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserProfile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/user/info', {  })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fileUpload(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/file/upload', payload )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },  
  },
}
